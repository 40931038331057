import * as dompack from 'dompack';

import { WWBasicSite } from '@mod-ww/webdesigns/template/ww';
import '@mod-ww/webdesigns/template/ww.scss';

import './default_base.scss';

dompack.onDomReady(() => {
  new WWBasicSite({});
});
